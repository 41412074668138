import React, { useEffect, useState } from "react";
import HeadingH2 from "./HeadingH2";

const HubspotContactForm = ({ id, title }) => {
  console.log("title", title);
  return (
    <div className="container">
      <HeadingH2>{title}</HeadingH2>
      <div id={"hbspt-form-" + id} />
    </div>
  );
};

const HubspotContactFormWrapper = ({ id, title }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "5510076",
          formId: id,
          target: "#hbspt-form-" + id,
          onFormReady: () => setLoading(false), // Update loading status when the form is ready
        });
      }
    });
  }, [id]);

  return (
    <div className="relative">
      <div className="container mx-auto  min-h-[70vh] flex flex-wrap items-center z-10 relative">
        <div className="w-full py-4 lg:p-20 text-tertiary text-4xl lg:text-6xl">
          {loading && (
            <div className="absolute left-0 top-0 right-0 bottom-0  flex items-center justify-center z-50">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
            </div>
          )}
          <HubspotContactForm id={id} title={title} />
        </div>
      </div>
      <div className="left-0 top-0 right-0 bottom-0 w-full h-full absolute bg-gradient-to-r from-white to-tertiary/30"></div>
    </div>
  );
};

export default HubspotContactFormWrapper;
