import { useState, useEffect } from "react";
import image1 from "../assets/media/iconen-03.png";
import image2 from "../assets/media/iconen-04.png";
import image3 from "../assets/media/iconen-05.png";

const WhyBlocks = ({ data }) => {
  const WhyBlocksContent = [
    { title: data.whyBox1, icon: image1, text: data.whyBoxtext1 },
    { title: data.whyBox2, icon: image2, text: data.whyBoxtext2 },
    { title: data.whyBox3, icon: image3, text: data.whyBoxtext3 },
  ];

  const [scales, setScales] = useState([1, 0.8, 0.7]);

  useEffect(() => {
    const interval = setInterval(() => {
      setScales((prevScales) => {
        const newScales = prevScales.map((scale) =>
          scale === 1 ? 0.7 : scale === 0.8 ? 1 : 0.8
        );
        return newScales;
      });
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="lg:flex lg:gap-8">
      {WhyBlocksContent.map((item, index) => (
        <div key={index} className="w-full lg:w-1/3">
          <div className="flex flex-col justify-center items-center py-8 my-2 lg:my-8 text-white uppercase bg-primaryGold">
            <h4 className="pb-3" style={{ whiteSpace: "pre-line" }}>
              {item.title.replace(" ", "\n")}
            </h4>
            <div className="min-h-[150px]">
              <img
                src={item.icon}
                width={130}
                height={130}
                alt={item.title}
                style={{
                  transform: `scale(${scales[index]})`,
                  transition: "transform 2s linear",
                }}
              />
            </div>
            <p className="pt-3 min-h-[80px]" style={{ whiteSpace: "pre-line" }}>
              {item.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhyBlocks;
