import React, { useState } from "react";

const YouTubeInPage = ({ videoId, thumbnailUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openVideo = () => {
    setIsOpen(true);
  };

  if (videoId === "") {
    return <div></div>;
  }

  return (
    <div>
      {!isOpen && (
        <button className="relative" onClick={openVideo}>
          <img
            src={thumbnailUrl}
            alt="Video Thumbnail"
            className="cursor-pointer rounded-xl"
          />
          <div className="play-button absolute left-0 top-0 right-0 bottom-0  flex items-center justify-center">
            <svg
              className="duration-300 hover:scale-125"
              viewBox="0 0 80 80"
              width="80"
              height="80"
            >
              <circle cx="40" cy="40" r="39" fill="#000" opacity="0.5" />
              <path d="M32 56.5v-33l22 16.5z" fill="#fff" />
            </svg>
          </div>
        </button>
      )}

      {isOpen && (
        <div className="relative w-full pb-[56.25%]">
          <iframe
            className="absolute w-full h-full top-0 left-0 rounded-xl"
            title="YouTube Video"
            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1&loop=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default YouTubeInPage;
