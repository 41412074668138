import React, { useState } from "react";
// import bgImage from "../assets/media/revinedbg.jpg";
import machineOverview from "../assets/media/machineOverview.jpg";
// import ProductPointsItem from "../components/ProductPointsItem";
import HowItWorksBlocks from "../components/HowItWorksBlocks";
import TheBenefitsBlocks from "../components/TheBenefitsBlocks";
import HeadingH2 from "../components/HeadingH2";
import FAQItems from "../components/FAQItems";

const TheProduct = ({ page }) => {
  const [showDiv, setShowDiv] = useState(false);

  const handleClick = () => {
    setShowDiv(!showDiv);
  };
  return (
    <>
      <div className="bg-secondaryBg relative overflow-hidden">
        <div className="container min-h-[70vh] flex flex-wrap items-center z-10 relative">
          <div className="w-full lg:w-1/2 py-4 lg:p-20 text-primaryBlue">
            <HeadingH2>{page.page_content.boxTitle}</HeadingH2>
            <p dangerouslySetInnerHTML={{ __html: page.page_content.box }} />
          </div>
          <div className="lg:w-1/2 relative">
            <img src={machineOverview} alt="Product" />
            <div
              className="absolute w-full h-full left-0 top-0 hidden lg:block cursor-pointer"
              onClick={handleClick}
            >
              <span className="absolute left-[62%] bottom-[62%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[50.8%] bottom-[58%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[47.5%] bottom-[58%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[62%] bottom-[25%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[62%] bottom-[15%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[69.5%] bottom-[15%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[33.3%] bottom-[55%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[41%] bottom-[28%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[40%] bottom-[46%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[28.7%] bottom-[32.5%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
              <span className="absolute left-[23%] bottom-[10%] z-10 flex items-center justify-center h-5 w-5 text-white  bg-gradient-to-bl from-secondary to-secondaryD rounded-full -translate-x-full translate-y-1/2">
                +
              </span>
            </div>
          </div>
        </div>
        {/* <div
          className={`absolute z-40 left-0 top-0 ${
            showDiv ? "w-full h-auto bg-white" : "w-0 h-0"
          }`}
        >
          <div
            className={`relative mx-auto transition-all duration-1000 ease-in-out ${
              showDiv ? "h-[70vh] aspect-16/11" : "w-0 h-0"
            }`}
          >
            <span
              className="z-50 absolute top-0 right-0 mx-4 text-[2.2rem] cursor-pointer text-primaryBlue hover:text-secondaryD"
              onClick={handleClick}
            >
              &times;
            </span>
            <img className="h-full w-auto" src={bgImage} alt="Product" />
            <div className="absolute w-full h-full left-0 top-0 hidden lg:block">
              <ProductPointsItem left="62" bottom="62">
                Adjustable cork with oxygen seal
              </ProductPointsItem>
              <ProductPointsItem left="50.8" bottom="58">
                Dispensing head for inert gas
              </ProductPointsItem>
              <ProductPointsItem left="47.5" bottom="58">
                Dispensing head for liquid
              </ProductPointsItem>
              <ProductPointsItem left="62" bottom="25">
                Filter fitted hose to sample from bottles without particles and
                wine deposits
              </ProductPointsItem>
              <ProductPointsItem left="62" bottom="15">
                Anti-slip bottle positioner
              </ProductPointsItem>
              <ProductPointsItem left="69.5" bottom="15">
                Handles for easy transportation
              </ProductPointsItem>
              <ProductPointsItem left="33.3" bottom="55">
                Cap chute for closing in protective atmosphere
              </ProductPointsItem>
              <ProductPointsItem left="41" bottom="28">
                Connection for inert gas tank
              </ProductPointsItem>
              <ProductPointsItem left="40" bottom="46">
                Switch for inert gas flow
              </ProductPointsItem>
              <ProductPointsItem left="28.7" bottom="32.5">
                100ml glass tube
              </ProductPointsItem>
              <ProductPointsItem left="23" bottom="10">
                50ml glass tube
              </ProductPointsItem>
            </div>
          </div>
        </div> */}
      </div>
      <div className="bg-primaryBlue relative w-full py-8">
        <div className="container flex flex-wrap justify-center text-white">
          <HeadingH2>How it works</HeadingH2>
          <HowItWorksBlocks />
        </div>
      </div>
      <div className="bg-secondaryBg w-full py-8">
        <div className="container flex flex-wrap justify-center py-8">
          <HeadingH2>
            The smartest way
            <br />
            to sample wine
          </HeadingH2>
          <TheBenefitsBlocks />
        </div>
      </div>
      <div className="bg-secondaryBlue w-full relative">
        <div className="container flex flex-wrap items-center py-8 text-white">
          <HeadingH2>FAQ</HeadingH2>
          <FAQItems />
        </div>
      </div>
    </>
  );
};

export default TheProduct;
