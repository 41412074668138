import React from "react";
import TopBox from "../components/TopBox";
import logo from "../assets/media/logo.png";
import iconVision from "../assets/media/icon-vision.svg";
import iconMission from "../assets/media/icon-mission.svg";
import aboutUsImage from "../assets/media/aboutUs.jpg";

const AboutUs = ({ page }) => {
  return (
    <>
      <TopBox data={page.page_content} />
      <div className="relative w-full py-8 bg-primaryBlue text-white">
        <div className="container min-h-[50vh] flex flex-wrap justify-center">
          <div className="w-full flex flex-col justify-center lg:w-2/3 lg:order-1 pr-6">
            <p className="text-lg font-light">
              At TUBES, we are truly passionate about wines and spirits. The
              world has countless wonderful wines and tasty spirits to offer,
              and we are on a mission to make them more obtainable. We created a
              sustainable, innovative, and straightforward way to introduce and
              present the best beverages worldwide. Our tubes are the perfect
              marketing tool that guarantees an incomparable tasting experience
              and encourages responsible drinking at the same time. We are a
              Dutch based company that bottles and distributes wines & spirits
              by the glass in elegant 50ml and 100ml glass or recycled plastic
              tubes. We bottled our first tube back in 2015. Over the last few
              years, we elevated what started out as an advanced luxury wine
              gift, to a multi-purpose tasting tool that benefits all kinds of
              markets and companies.
            </p>
          </div>
          <div className="w-full flex flex-col justify-center lg:w-1/3 lg:order-2 pl-6">
            <img src={logo} alt="About us logo" />
          </div>
        </div>
      </div>
      <div className="relative w-full py-8 bg-secondaryBg text-primaryBlue">
        <div className="container min-h-[50vh] flex flex-wrap justify-center">
          <div className="w-full flex flex-col justify-center lg:w-1/2 lg:px-4">
            <div className="flex bg-primaryGold text-white p-20">
              <img className="order-1 me-2" src={iconVision} alt="Vision" />
              <div className="order-2 ms-2">
                <h3 className="text-xl font-semibold">Vision</h3>
                <p>Unlimited taste by the glass</p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center lg:w-1/2 lg:px-4">
            <div className="flex bg-primaryGold text-white p-20">
              <img className="order-2 ms-2" src={iconMission} alt="Mission" />
              <div className="order-1 me-2 text-right">
                <h3 className="font-bold">Mission</h3>
                <p>
                  Be the most trusted and value adding single-serve producer in
                  the industry
                </p>
              </div>
            </div>
          </div>
          <img
            src={aboutUsImage}
            className="w-full lg:p-4 block mx-auto"
            alt="About Us"
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
