import { Link } from "react-router-dom";
import bgImage from "../assets/media/revinedbg.jpg";
import HeadingH2 from "./HeadingH2";
const TheConcept = ({ data }) => {
  const handleMenuItemClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="relative bg-secondaryBg w-full py-8">
      <div className="container text-primaryBlue lg:ps-20">
        <div className="mx-auto flex flex-wrap items-center z-10 relative">
          <div className="w-full lg:w-1/2 py-4 lg:pe-20">
            <HeadingH2>{data.theConceptTitle}</HeadingH2>
            <p dangerouslySetInnerHTML={{ __html: data.theConceptText }} />
          </div>
          <div className="w-full lg:w-1/2 py-4 lg:px-20">
            <img src={bgImage} alt="" className="mb-3" />
          </div>
        </div>
        <Link
          to={data.theConceptBtnUrl}
          onClick={handleMenuItemClick}
          className="mx-auto h-100 px-[1rem] py-[.5rem] bg-primaryBlue text-lg text-white fill-white font-semibold duration-300 hover:bg-primaryGold"
        >
          {data.theConceptBtn}
          <span className="inline-block  ms-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="13"
              viewBox="0 0 16 13"
            >
              <g>
                <g>
                  <path d="M9.583 0L7.966 1.655l3.638 3.685H0v2.32h11.604l-3.638 3.685L9.583 13 16 6.5z" />
                </g>
              </g>
            </svg>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default TheConcept;
