import { Link } from "react-router-dom";
import logo from "../assets/media/logo.png";
import ContactLocation from "../components/footer/ContactLocation";
import CopyRightsText from "../components/footer/CopyRightsText";
import FooterLinks from "../components/footer/FooterLinks";
import Newsletter from "../components/footer/Newsletter";
import Partner from "../components/footer/Partner";
import Socials from "../components/footer/Socials";

const Footer = () => {
  return (
    <footer>
      <div className="bg-primaryBlue text-white min-h-[150px] flex flex-col justify-end">
        <div className="flex justify-center py-8">
          <Link to="/">
            <img
              width=""
              height=""
              className="max-h-[50px] lg:max-h-[75px] w-auto"
              src={logo}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="container flex flex-wrap">
          <div className="flex flex-col items-center py-8 px-4 w-full lg:w-1/4">
            <ContactLocation location="EU" />
          </div>
          <div className="flex flex-col items-center py-8 px-4 w-full lg:w-1/4">
            <ContactLocation location="US" />
          </div>
          <div className="flex flex-col items-center py-8 px-4 w-full lg:w-1/4">
            <ContactLocation location="APAC" />
          </div>
          <div className="flex flex-col items-center py-8 px-4 w-full lg:w-1/4">
            <ContactLocation location="LATAM" />
          </div>
        </div>
        <div className="container flex flex-wrap lg:justify-around">
          <div className="text-center flex flex-col py-8 items-center w-full lg:w-1/4">
            <Newsletter />
          </div>
          <div className="text-center flex flex-col py-8 px-4 items-center w-full lg:w-1/4">
            <Socials />
          </div>
        </div>
        <ul className="flex flex-wrap items-center justify-center gap-6">
          <FooterLinks />
        </ul>
        <Partner />
        <CopyRightsText />
      </div>
    </footer>
  );
};

export default Footer;
