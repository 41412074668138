const ContactLocation = ({ location }) => {
  if (location === "US") {
    return (
      <>
        <h3 className="pb-3">Contact USA</h3>
        <p className="pb-3 text-center">
          TUBES USA Inc.
          <br />
          520 Airpark Rd,
          <br />
          Napa, CA 94558
          <br />
          USA
        </p>
        <p className="text-center">
          <a
            href="mailto:sales@revined.com"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            sales@revined.com
          </a>
          <br />
          <a
            href="tel:+14165666025"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            +1 (919) 601 8070
          </a>
        </p>
      </>
    );
  } else if (location === "EU") {
    return (
      <>
        <h3 className="pb-3">Contact Europe</h3>
        <p className="pb-3 text-center">
          TUBES Production Company B.V.
          <br />
          Franciscusweg 14,
          <br />
          1216 SK Hilversum,
          <br />
          The Netherlands
        </p>
        <p className="text-center">
          <a
            href="mailto:sales@revined.com"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            sales@revined.com
          </a>
          <br />
          <a
            href="tel:+31353034994"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            +31 (0)35 303 4994
          </a>
        </p>
      </>
    );
  } else if (location === "APAC") {
    return (
      <>
        <h3 className="pb-3">Contact APAC</h3>
        <p className="pb-3 text-center">
          Revined Australia
          <br />
          Unit 0116, 27 Clarinda Rd
          <br />
          3167 Oakleigh South
          <br />
          Victoria Australia
        </p>
        <p className="text-center">
          <a
            href="mailto:sales@revined.au"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            sales@revined.au
          </a>
          <br />
          <a
            href="tel:+61409033640"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            +61 (0) 409 033 640

          </a>
        </p>
      </>
    );
  } else if (location === "LATAM") {
    return (
      <>
        <h3 className="pb-3">Contact LATAM</h3>
        <p className="pb-3 text-center">
          POL Argentina S.A.
          <br />
          VRA International SpA
          <br />
          <br />
          <br />
        </p>
        <p className="text-center">
          <a
            href="mailto:roon.vanrhee@wineintubes.com"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            roon.vanrhee@wineintubes.com
          </a>
          <br />
          <a
            href="tel:+5492614857067"
            className="text-secondaryBlue font-bold hover:text-primaryGold"
          >
            +54 9261 485 7067
          </a>
        </p>
      </>
    );
  }
};

export default ContactLocation;
